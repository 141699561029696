export default {
    en: {
        "Office no. {value}": "Office no. {value}",
        "House no. {value}": "House no. {value}",
        "Floor no. {value}": "Floor no. {value}",
        "Apartment no. {value}": "Apartment no. {value}",
        "{value} Building": "{value} Building",
        "{value} Avenue": "{value} Avenue",

        "{field} is a required field": "{field} is a required field",
        "{field} is a not valid field": "{field} is a not valid field",

        "Reopen at {time}": "Reopen at {time}",
        "Select at least {min}": "Select at least {min}",
        "Select up to {max}": "Select up to {max}",
        "{count} items": "{count} items",
        "{count} products": "{count} products",
        "{value} OFF!": "{value} OFF!",
        "Use code {code}": "Use code {code}",
        "{minutes} minutes": "{minutes} minutes",
        "{days} days": "{days} days",
        "{hours} hour": "{hours} hour",
        "{hours} hours": "{hours} hours",
        "Tomorrow {time}": "Tomorrow {time}",
        "Closes at {time}": "Closes at {time}",
        "Max quantity available is for {option} is {qty}": "Max quantity available is for {option} is {qty}",
        "Max quantity available is {qty}": "Max quantity available is {qty}",
        "{item} quantity updated in cart": "{item} quantity updated in cart",
        "{item} added to cart": "{item} added to cart",
        'order_terms': 'By placing this order you acknowledge and agree to the <a href="{ href }" target="_blank">Terms and Conditions</a>',
        'account_terms': 'By creating an account you agree to our <a target="_blank" href="{privacy}">Privacy Policy</a> and <a target="_blank" href="{terms}">Terms and Conditions</a>',
    },
    ar: {
        "Item out of stock.": "العنصر غير متوفر.",
        "Variant detail missing.": "تفاصيل المتغير مفقودة.",
        "Variant out of stock.": "المتغير غير متوفر.",
        "Item out of stock:": "العنصر غير متوفر:",
        "Variant detail missing:": "تفاصيل المتغير مفقودة:",
        "Variant out of stock:": "المتغير غير متوفر:",
        "Continue Shopping": "متابعة التسوق",
        "Powered by": "مشغل بواسطة",
        "Floor": "أرضية",
        "Optional": "خياري",
        "optional": "خياري",
        "Enter apartment": "أدخل الشقة",
        "Apartment is a not valid field": "الشقة حقل غير صالح",
        "We could not get your location": "لم نتمكن من الحصول على موقعك",
        "Please provide location permission in your browser": "يرجى تقديم إذن الموقع في المتصفح الخاص بك",
        "Block number (optional)": "القطعه (اختياري)",
        "Enter block number": "ادخل رقم الكتلة",
        "Block is a not valid field": "الكتلة ليست حقلًا صالحًا",
        "Avenue (optional)": "شارع (اختياري)",
        "Enter avenue name": "أدخل اسم الشارع",
        "Avenue is a not valid field": "الشارع ليس حقلًا صالحًا",
        "Email not found in inbox? Please check spam folder.": "لم يتم العثور على البريد الإلكتروني في البريد الوارد؟ يرجى التحقق من مجلد الرسائل غير المرغوب فيها.",
        "Complete payment": "دفع كامل",
        "A new version is available": "إصدار جديد متوفر",
        "Refresh": "ينعش",
        "Opps! Store Not Found": "انتبه! لم يتم العثور على المتجر",
        "Contact Us": "اتصل بنا",
        "Office no. {value}": "رقم المكتب. {value}",
        "House no. {value}": "رقم المنزل. {value}",
        "Floor no. {value}": "رقم الطابق. {value}",
        "Apartment no. {value}": "شقة رقم. {value}",
        "{value} Building": "{value} بناء",
        "{value} Avenue": "{value} الجادة",
        "Sender": "مرسل",
        "All": "الجميع",
        "Login Now": "تسجيل الدخول الآن",
        "Your will receive password reset instruction in your mailbox.": "سوف تتلقى تعليمات إعادة تعيين كلمة المرور في صندوق البريد الخاص بك.",
        "Delivering to": "التوصيل الى",
        "Delivery Fee": "رسوم التوصيل",
        "Store business hours": "ساعات عمل المتجر",
        "Hello": "مرحبًا",
        "Guest": "زائر",
        "YouTube": "يوتيوب",
        "Twitter": "تويتر",
        "Facebook": "فيسبوك",
        "Instagram": "انستجرام",
        "eMail": "البريد الإلكتروني",
        "WhatsApp": "واتساب",
        "Snapchat": "سناب شات",
        "Weblink": "رابط موقع",
        "Track": "تتبع",
        "Order Code": "رمز الطلب",
        "Track your order": "تتبع طلبك",
        "Store Info": "معلومات المتجر",
        "Addresses": "عنوان",
        "Orders": "طلبات",
        "Profile": "الملف الشخصي",
        "Close now": "إغلاق الآن",
        "Open now": "مفتوح الآن",
        "Reopen at {time}": "إعادة الفتح في {time}",
        "Branches": "أفرع",
        "Unable to Verify Your Email": "غير قادر على التحقق من بريدك الإلكتروني",
        "Unable to Sign Up": "غير قادر على التسجيل",
        "Important message": "رسالة مهمة",
        "We've sent you a confirmation link": "لقد أرسلنا لك رابط التأكيد",
        "Please check your email so you can verify your account": "يرجى التحقق من بريدك الإلكتروني حتى تتمكن من التحقق من حسابك",
        "Resend Confirmation Link": "إعادة إرسال رابط التأكيد",
        "Okay": "حسنا",
        "Pay with Moyasar": "ادفع باستخدام ميسر",
        "Pay with Stripe": "ادفع باستخدام سترايب",
        "Pay now": "ادفع الآن",
        "Alert": "تنبيه", 
        "An unexpected error occurred.": "حدث خطأ غير متوقع",
        "Pickup from": "الاستلام من", 
        "Search branches": "البحث في الفروع",
        "Your cart": "سلة التسوق",
        "Checkout": "الدفع",
        "Start Shopping": "ابدأ التسوق",
        "You can manage your shopping cart here": "يمكنك إدارة عربة التسوق الخاصة بك هنا",
        "Your cart is empty": "عربة التسوق فارغة",
        "Delivery": "توصيل",
        "Pickup": "الاستلام",
        "Place order": "تأكيد الطلب",
        "Total amount": "المبلغ الإجمالي",
        "Discount": "خصم",
        "Delivery fees": "رسوم التوصيل",
        "Cart total": "مجموع سلة التسوق",
        "Payment summary": "ملخص الدفع",
        "Edit": "تعديل",
        "Make it a gift": "اجعلها هدية",
        "Submit": "تقديم",
        "Applied": "تم تفعيل",
        "Change": "تغيير",
        "Delivery Instructions": "تعليمات التسليم",
        "Schedule": "موعد المحدد",
        "Delivery Time": "موعد التسليم",
        "Select Location": "تحديد الموقع",
        "Delivery not available for selected location": "التسليم غير متاح للموقع المحدد",
        "Contact Info": "معلومات جهة الاتصال",
        "Add Contact Details": "إضافة بيانات جهة اتصال",
        "Select Pickup Location": "حدد موقع الاستلام",
        "Pay with": "الدفع باستخدام",
        "Not supporting delivery and store pickup!": "!لا يدعم التوصيل والاستلام من المتجر",
        "There seems to be an issue with store configuration.": "يبدو أن هناك مشكلة في إعدادات المتجر.",
        "Payment Failed": "عملية الدفع فشلت",
        "There seems to be an issue with your payment, please try again.": "يبدو أن هناك مشكلة في الدفع، يرجى المحاولة مرة أخرى.",
        "Delivery not supported in selected location": "التسليم غير متاح في الموقع المحدد",
        "Contact Details": "بيانات المتصل",
        "Name is a not valid field": "الاسم غير صالح",
        "Phone number is a not valid field": "رقم الهاتف غير صالح",
        "Email is a not valid field": "البريد الإلكتروني غير صالح",
        "Enter name": "أدخل الاسم",
        "Enter email": "أدخل البريد الإلكتروني",
        "Enter phone no.": "أدخل رقم الهاتف",
        "Gift Details": "تفاصيل الهدية",
        "Discard": "تجاهل",
        "Display sender name": "عرض اسم المرسل",
        "Recipient": "المستلم",
        "Recipient Name": "اسم المستلم",
        "Recipient Phone Number": "رقم هاتف المستلم",
        "Gift Message": "رسالة للهدية",
        "Save": "حفظ",
        "Enter Recipient Name": "أدخل اسم المستلم",
        "Enter Recipient Phone Number": "أدخل رقم هاتف المستلم",
        "Enter Gift Message": "أدخل رسالة للهدية",
        "Delivery Info": "معلومات التوصيل",
        "Name": "الاسم",
        "Phone no.": "رقم الهاتف",
        "Email": "البريد الإلكتروني",
        "Area": "المنطقة",
        "City": "المدينة",
        "Location": "الموقع",
        "Set your location": "حدد موقعك",
        "Location is a required field": "الموقع مطلوب",
        "House": "المنزل",
        "Apartment": "الشقة",
        "Work": "العمل",
        "Unit type": "نوع الوحدة",
        "Unit type is a required field": "نوع الوحدة مطلوب",
        "Office No.": "رقم المكتب",
        "Enter office no.": "أدخل رقم المكتب",
        "Office number is a not valid field": "رقم المكتب غير صالح",
        "House No.": "رقم المنزل",
        "Enter house no.": "أدخل رقم المنزل",
        "House number is a not valid field": "رقم المنزل غير صالح",
        "Apartment No.": "رقم الشقة",
        "Enter apartment no.": "أدخل رقم الشقة",
        "Apartment number is a not valid field": "رقم الشقة غير صالح",
        "Floor (optional)": "الطابق (اختياري)",
        "Enter floor no.": "أدخل رقم الطابق",
        "Floor number is a not valid field": "رقم الطابق حقل غير صالح",
        "Building is a not valid field": "المبنى هو حقل غير صالح",
        "Enter building name": "أدخل اسم المبنى",
        "Building name (optional)": "اسم المبنى (اختياري)",
        "Street": "الشارع",
        "Enter street": "أدخل الشارع",
        "Postalcode/ Zipcode/ Pincode": "الرمز البريدي/رقم المنطقة/الرمز الشخصي",
        "Enter Postalcode/ Zipcode/ Pincode": "أدخل الرمز البريدي/رقم المنطقة/الرمز الشخصي",
        "Additional directions (optional)": "اتجاهات إضافية (اختياري)",
        "Enter additional directions": "أدخل اتجاهات إضافية",
        "Delivery Options": "خيارات التوصيل",
        "Leave at door": "ترك عند الباب",
        "Hand it to me": "سلمها لي",
        "Leave with guard": "اترك مع الحرس",
        "Enter delivery instructions": "أدخل تعليمات التسليم",
        "Pickup Time": "وقت الاستلام",
        "Schedule order": "حدد موعد الطلب",
        "Set time": "ضبط الوقت",
        "Order Mode": "وضع الطلب",
        "Deliver to": "التوصيل إلى",
        "Search Country": "ابحث عن البلد",
        "Search state": "ابحث عن ولاية",
        "Search city": "ابحث عن المدينة",
        "Search areas": "البحث عن المناطق",
        "This store currently doesn't deliver to any country": "هذا المتجر لا يقوم بالتوصيل إلى أي دولة حاليًا",
        "This store currently doesn't support pickup": "هذا المتجر لا يدعم الاستلام حاليًا",
        "Sorry!": "!نعتذر",
        "This store currently doesn't deliver to this location": "هذا المتجر لا يقوم بالتوصيل إلى هذا الموقع حاليًا",
        "Oops! Page Doesn't Exist": "عفوا! الصفحة غير موجودة",
        "The page may have moved or there is a problem on our end.": "ربما تم نقل الصفحة أو أن هناك مشكلة من جانبنا.",
        "Refresh Page": "قم بتحديث الصفحة",
        "Server Error": "خطأ في السيرفر",
        "There seems to be an error from our end.": "يبدو أن هناك خطأ من جانبنا",
        "Error": "خطأ",
        "Please, enable cookies/ storage.": "يرجى تمكين ملفات تعريف الارتباط/التخزين",
        "Make sure your device is connected to the internet": "تأكد من أن جهازك متصل بالإنترنت",
        "No Internet Connection": "لا يوجد اتصال بالإنترنت",
        "Home": "المنزل",
        "No items added yet!": "!لم تتم إضافة أي منتجات حتى الآن",
        "Required": "مطلوب",
        "Select at least {min}": "{min}اختر على الأقل",
        "Select up to {max}": "حدد ما يصل إلى {max}",
        "Variations": "التغيرات",
        "In Stock": "متوفر",
        "Out of Stock": "غير متوفر",
        "Add to Cart": "أضف إلى السلة",
        "Share with Twitter": "شارك مع تويتر",
        "Categories": "الاقسام",
        "{count} items": "{count} المنتجات",
        "No items added in selected category yet!": "لم تتم إضافة أي منتج في القسم المحدد حتى الآن",
        "Search here": "ابحث هنا",
        "{count} products": "{count} المنتجات",
        "Price on selection": "السعر عند الاختيار",
        "Sold out": "نفذت الكمية",
        "Add to cart": "أضف إلى السلة",
        "{value} OFF!": "{value} !إيقاف",
        "Free delivery!": "!توصيل مجاني",
        "Use code {code}": "استخدم الكود {code}",
        "{minutes} minutes": "{minutes} دقائق",
        "Tomorrow": "غداً",
        "{days} days": "{days} أيام",
        "{hours} hour": "{hours} ساعة",
        "{hours} hours": "{hours} ساعات",
        "Tomorrow {time}": "غدا {time}",
        "Order anytime!": "!اطلب في أي وقت",
        "Closes at {time}": "يغلق في {time}",
        "Add New Address": "إضافة عنوان جديد",
        "You can manage your address book from here": "تستطيع ان تعدل على ملف العناوين من هنا",
        "Unable to Update": "غير قادر على التحديث",
        "Update Address": "تحديث العنوان",
        "Edit Address": "تعديل العنوان",
        "Add Address": "إضافة عنوان",
        "Select country": "اختيار البلد",
        "Select city": "اختر المدينة",
        "Select state": "أختيار الولاية",
        "Select area": "حدد المنطقة",
        "{count} Items": "{count} العناصر",
        "Subtotal": "الإجمالي الفرعي",
        "Grand Total": "المجموع الكلي",
        "Order Now": "اطلب الآن",
        "Your orders will appear here once you make an order": "ستظهر طلباتك هنا بمجرد إجراء الطلب",
        "No orders history yet": "لا يوجد سجل للطلبات حتى الآن",
        "Save Details": "حفظ التفاصيل",
        "Enter phone number": "أدخل رقم الهاتف",
        "Phone Number": "رقم الهاتف",
        "Enter last name": "أدخل الاسم الأخير",
        "Last Name": "الاسم الأخير",
        "Enter first name": "أدخل الاسم الأول",
        "First Name": "الاسم الأول",
        "Cancel": "إلغاء",
        "Continue with Email": "الاستمرار مع البريد الإلكتروني",
        "Password": "كلمة المرور",
        "Enter password": "أدخل كلمة المرور",
        "Forgot your password?": "نسيت كلمة السر؟",
        "Login": "تسجيل الدخول",
        "Don’t have an account?": "ليس لديك حساب؟",
        "Create an Account": "إنشاء حساب",
        "Recover Password": "إستعادة كلمة المرور",
        "You will receive password reset instruction in your mailbox.": "سوف تتلقى تعليمات إعادة تعيين كلمة المرور في صندوق البريد الخاص بك",
        "Success": "نجحت العملية",
        "Unable to Recover": "غير قادر على إستعادة",
        "Continue with BAWES": "BAWES الاستمرار مع",
        "Continue with Google": "Google الاستمرار مع",
        "Continue with Apple": "Apple الاستمرار مع",
        "Already have an account?": "هل لديك حساب؟",
        "First name is a required field": "الاسم الأول هو حقل مطلوب",
        "Last name is a required field": "الاسم الأخير هو حقل مطلوب",
        'order_terms': 'من خلال تقديم هذا الطلب، فإنك تقر وتوافق على <a href="{ href }" target="_blank">الشروط والأحكام</a>',
        'account_terms' : 'من خلال إنشاء حساب، فإنك توافق على <a target="_blank" href="{privacy}">سياسة الخصوصية</a> و<a target="_blank" href="{terms}">الشروط والأحكام </a>.',
        "New Password": "كلمة مرور جديدة",
        "Enter new password": "أدخل كلمة مرور جديدة",
        "Update password": "تحديث كلمة المرور",
         
        "Enter discount coupon here": "أدخل قسيمة الخصم هنا",
        "Office": "مكتب",
        "house": "المنزل",
        "apartment": "الشقة",
        "work": "العمل",
        "office": "مكتب",
        "Log Out": "تسجيل خروج",
        "Delivery option not available for now! Please contact store owner.": "خيار التوصيل غير متاح حاليًا! يرجى الاتصال بصاحب المتجر.",

        "Max quantity available is for {option} is {qty}": "الحد الأقصى للكمية المتاحة لـ {option} هو {qty}",
        "Max quantity available is {qty}": "الحد الأقصى للكمية المتاحة هو {qty}", 
        "{item} quantity updated in cart": "تم تحديث كمية {item} في سلة التسوق",
        "{item} added to cart": "تمت إضافة {item} إلى سلة التسوق",
        "We could not find your order!": "لم نتمكن من العثور على طلبك!",
        "Please check if order/invoice id is corrent.": "يرجى التحقق مما إذا كان معرف الطلب/الفاتورة صحيحًا.",

        "{field} is a required field": "{field} هو حقل مطلوب",
        "{field} is a not valid field": "{field} غير صالح",
        "First name": "الاسم الأول",
        "Last name": "اسم العائلة",

        "Phone number": "رقم التليفون",
        "Passwords should be between 6 and 16 characters.": "يجب أن تتراوح كلمات المرور بين 6 و16 حرفًا.",

        "Unable to Login": "غير قادر على تسجيل الدخول",
        "Oops...": "أُووبس...",
        "Unable to login, please check your credentials!": "غير قادر على تسجيل الدخول، يرجى التحقق من بيانات الاعتماد الخاصة بك!"
    }
}